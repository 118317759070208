<template>
  <v-row class="fill-height">
    <v-col>
      <h1 class="text-center">Have some questions?</h1>
      <h2 class="text-center mb-2">Here is what other people have asked so far.</h2>
      <p class="text-center">If you don't find what you are looking for, send us a message.</p>
      <v-expansion-panels
          accordion
      >
        <v-expansion-panel
            v-for="(faq,i) in faqs"
            :key="i"
        >
          <v-expansion-panel-header><strong>{{ faq.question }}</strong></v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="ma-0" v-html="faq.answer"></p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FAQ',
  data: () => ({
    faqs: [
      {
        question: 'How do I stop receiving texts if I don\'t want them anymore?',
        answer: 'To stop the pain tracking texts simply text "Stop" to the next text you receive. We will then remove your number from the pilot and the database.'
      },
      {
        question: 'How do I best use the platform?',
        answer: 'Respond to the texts from the rheumatoid arthritis pain management platform by tapping the link in the texts and responding to the input points that indicate your pain, fatigue and swelling on a bi-weekly basis. Use this link as a way to provide your rheumatologist some insights around how you feel from a week-to-week basis. Simply bring it into3 your appointments and use it as a way to guide the' +
            'conversation around how to adjust your care plan.' +
            '<br/>' +
            '<br/>' +
            'NOTE: To find your link sooner, we suggest saving the number under a name you will remember so that you can access the information rapidly.'
      },
      {
        question: 'Who are we?',
        answer: '<a href="https://www.linkedin.com/in/trevor-wilkins-a5472b30/" target="_blank">Trevor Wilkins</a> and <a href="https://www.linkedin.com/in/roberthaidari/" target="_blank">Robert Haidari</a> are serial entrepreneurs from Chicago who built the rheumatoid arthritis pain management platform to help patients with chronic pain visualize their pain in between doctor visits. We are testing this product at its Minimal Viable Product stage to see if patients will respond to the digital pain management tool delivered via text.'
      },
      {
        question: 'How do we keep the data secure?',
        answer: `We encrypt and hash all information sent to us, do not transport any identifiable personal data in our client facing application, or share it with any third parties.`
      },
    ]
  }),
};
</script>
